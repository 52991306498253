.contact{
    color: white;
    height:100%;
    width: 100%;
}

#join{
    background-image: url(../img/eosas.png);
    height:100%;
    padding-bottom: 50px;
    text-align: center;
    margin-bottom: 10px;

}

#text1{
    width: 50%;
    padding-top: 15%;
    padding-left: 25%;

}
#text2{
    padding-top: 35%;

}
#sister{
    padding-right: 400px;
    padding-left: 400px;
    padding-bottom: 400px;
    margin-left: 15px;
    background-image: url(../img/calros.png);
    background-repeat: no-repeat;
    color:white;
    text-align: center;
    width:40%;
}