.App {
  text-align: center;
  height: 100%;
}



html,body, #root{
  height: 100%;
  max-width: 100%;
  text-align: center;
}



#exhibit{
  border-style: solid;
  background: linear-gradient(#bccae4, #9b9ca2);
  height: 75%;
  width: 75%;
  margin:auto;
  

}
