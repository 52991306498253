.virtual {
  text-align: center;
  height: 100%;
  color: white;
}

p{
  width:100%;
}

