.makeup{
    color: white;
    height:100%;
}

div.gallery {
    margin-top: 25px;
    margin-left: 75px;
    margin-right: 50px;
    margin-bottom: 25px;
    border: 1px solid #ccc;
    float:left;
    width: 225px;
  }
  
  div.gallery:hover {
    border: 1px solid #777;
  }
  
  div.gallery img {
    width: 100%;
    height: auto;
  }
  
  div.desc {
    padding: 15px;
    text-align: center;
  }

  #header{
      height: 75%;
      background-image: url(../img/makeup.png);
      margin:0;
      padding: 50px;
      font-size: 50px;
      background-repeat: no-repeat;
      text-align: center;
  }